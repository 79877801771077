const imgs = require('../photo_2024-10-12_11-23-02.jpg')
const sec = require('../sec.jpg')

export default function love(){
    return(
    <div className='flex flex-col items-center h-full bg-pink-300'>
        <div>
            <img src={imgs} className='w-full blur-[0.6px] '/>
            <h1 className='block'>Hi</h1>
        </div>
        
        <div className="flex justify-center items-center w-full h-[100px] mb-[15px] bg-blue-200 rounded-b-2xl">
        </div>
        <div className="flex w-full h-full flex-col items-center justify-center ">
            <h1 className="font-bold">ujepah ❤️ Syira</h1>
            <div className='bg-white w-[85%] mr-auto rounded-r-2xl '>
                <p className='mr-auto ml-3 mt-4 mb-4 w-[85%] font-semibold'>
                    In you, I’ve found a love that transcends time.
                     Every moment together is a beautiful chapter 
                     filled with joy and affection. Your smile 
                     lights up my world, and your presence brings 
                     a warmth I’ve never known. You are my dream 
                     come true, my soulmate, and forever mine. 
                     My love for you will always remain, growing 
                     stronger every day.<br/>
                     I will forever be yours.
                </p>
            </div>
            <img src={sec} className='rounded-2xl w-[60%] mt-4 mb-4 ml-auto'/>
        </div>
    </div>
    )
}